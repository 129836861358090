:root {
  --scroll-bar-width: 0px;
}

.pretty_scroll_area__parent {
  overflow-x: hidden !important;
}
.pretty_scroll_area__parent > .pretty_scroll_area {
  width: 100vw !important;
  height: 100vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  transition: width 0.3s linear !important;
}
.pretty_scroll_area__parent > .pretty_scroll_area > * {
  /* We need to ensure that there is not overflow on the x-axis */
  /* To create an x-axis scroll: create a new scrolling child element */
  max-width: 100vw;
}
.pretty_scroll_area__parent > .pretty_scroll_area.hide_scroll {
  width: calc(100vw + var(--scroll-bar-width)) !important;
}
