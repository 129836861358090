.zooming__scaler {
  transform-origin: var(--zoom-center-x) var(--zoom-center-y);
  transition: transform 0.6s ease;
}
.zoomed-state .zooming__scaler {
  transform: translate(var(--translate-x), var(--translate-y))
    scale(var(--scale));
}

.zoomed-state .zooming__overflow-container,
.zooming__overflow-container.zoom-transition {
  overflow: hidden !important;
  /* I don't know why but 100% doesn't work for TT */
  height: 100vh !important;
  width: 100% !important;
}

.zooming__zoomable-element {
  cursor: pointer;
}
