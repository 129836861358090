#header-container {
  text-align: center;
}
#header-container > #header-content {
  display: inline-flex;
}

.footer-section-title {
  color: #5a5d61;
}

#footer_container {
  padding-top: 40px;
}

.more_panel_block {
  margin: auto;
  max-width: var(--max-content-width);
  padding-left: var(--content-side-padding);
  padding-right: var(--content-side-padding);
  padding-top: 17px;
}
.more_panel_block ~ .more_panel_block {
  padding-top: 34px;
}

#fv_left-side {
  flex-grow: 0;
  flex-shrink: 0;
}

#fv_right-side {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}

#fv_full-view-layout {
  width: 100vw;
  display: flex;
  align-items: stretch;
}

#fv_left-side {
  position: relative;
}
#fv_left-side > #fv_left-side_background {
  position: absolute;
}
#fv_left-side_background {
  position: absolute;
  width: 100%;
  height: 100%;
  border-right: 2px solid var(--text-color);
}

#fv_right-side > :not(.pretty_scroll_area__hide_scroll_element) {
  padding-right: var(--scroll-bar-width);
}
